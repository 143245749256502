import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Evolving Health - Kimberly Plomp, PhD</title>
            <meta name="description" content="Evolving Health book information" />
            <meta name="keywords" content="evolution,health,research" />
        </Helmet>

        <BannerLanding title="Evolving Health" subtitle="Integrating palaeopathology and evolutionary medicine" />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h3>Learning from the past<br />to understand our health</h3>
                    </header>
                    <p><i>‘The great mystery of medicine is the presence, in a machine of exquisite design, of what seem to be flaws, frailties, and makeshift mechanisms that give rise to most disease’</i><br/>(Nesse and Williams 1994:3)</p>
                    <a href="https://global.oup.com/academic/product/palaeopathology-and-evolutionary-medicine-9780198849728" target="_blank" rel="noreferrer"><StaticImage src="../assets/images/evolving_health.webp" alt="Evolving Health" className="image main" objectFit="contain" /></a>
                    <p>Evolutionary medicine is a fast-growing field that has been gaining recognition not only in modern clinical research but also in bioarchaeology and especially, palaeopathology. One important aspect of evolutionary medicine is the ability to explore and explain how and why certain diseases have emerged and evolved in humans. One way that evolutionary medicine can do this is by investigating disease directly in past human populations using bioarchaeological methods and theory. Currently, however, palaeopathology is not necessarily recognised as particularly useful to the field and most key texts in evolutionary medicine do not include a discussion on palaeopathology.</p>
                    <p>Palaeopathology has the potential to provide direct insight into the appearance, prevalence, manifestations, and impact of particular health problems throughout human history and prehistory. The information that can be gained through the analysis of archaeological skeletal remains can help medical researchers and clinicians appreciate how and why diseases appeared, evolved, and changed through human history. It does so by providing a unique perspective through longitudinal studies of the remains of human populations from different geographic locations and cultures. Additionally, the information gained from diverse, longitudinal studies of past population within their archaeological contexts has the potential to help identify possible aetiological factors that can be difficult to see in smaller more locally confined clinical studies that are, by necessity, much more short term in their scope.</p>
                    <p>Considering the potential synergy of the two fields, I have been working towards highlighting this potential in several ways. First, I am lead editor of an edited volume called <a href="https://global.oup.com/academic/product/palaeopathology-and-evolutionary-medicine-9780198849728" target="_blank" rel="noreferrer"><i>‘Palaeopathology and Evolutionary Medicine: an integrated approach’</i> published by Oxford University Press</a>. This volume presents cutting edge interdisciplinary research by a number of specialists from diverse backgrounds, thereby providing an innovative approach to palaeopathology and evolutionary medicine.</p>
                    <p>Second, I have initiated a virtual issue of the journal <a href="https://academic.oup.com/emph/pages/call-for-papers-evolutionary-medicine-and-palaeopathology" target="_blank" rel="noreferrer">Evolution, Medicine, and Public Health</a> that is currently accepting submissions of papers that combine palaeopathology and evolution medicine.</p>
                    <p>Lastly, I have organized and run symposia on Palaeopathology and Evolutionary Medicine at the annual meeting of the <a href="https://isemph.org/" target="_blank" rel="noreferrer">International Society of Evolution, Medicine, and Public Health.</a></p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
